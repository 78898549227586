import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import ExcelJs from "exceljs";
import header from "../httpHeader.json" assert { type: "json" };
import des from "../DES.json" assert { type: "json" };

const CryptoJS = require("crypto-js");
const XLSX = require("xlsx");

// const AesKey = "CD12!@pl(oPP456p";
// const AesIV = "XY73!n3uG3499@oZ";

// const DesKey = "CD12!@pl";
// const DesIV = "(oPP456p";

//日期 format yyyy-MM-dd hh:mm:ss
Date.prototype.format = function (format) {
  //eg:format="yyyy-MM-dd hh:mm:ss";
  if (!format) {
    format = "yyyy-MM-dd HH:mm:ss";
  }

  var o = {
    "M+": this.getMonth() + 1, // month
    "d+": this.getDate(), // day
    "H+": this.getHours(), // hour
    "h+": this.getHours(), // hour
    "m+": this.getMinutes(), // minute
    "s+": this.getSeconds(), // second
    "q+": Math.floor((this.getMonth() + 3) / 3), // quarter
    S: this.getMilliseconds(),
  };

  if (/(y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }

  for (var k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return format;
};

const uuid = () => {
  return uuidv4();
};

const readxlsx = (inpdata, fmt) => {
  //讀取xlsx檔

  //參數
  //inpdata為由input file讀入之data
  //fmt為讀取格式，可有"json"或"csv"，csv格式之分欄符號為逗號，分行符號為[\n]

  //說明
  //所使用函式可參考js-xlsx的GitHub文件[https://github.com/SheetJS/js-xlsx]

  //to_json
  function to_json(workbook) {
    var result = {};
    workbook.SheetNames.forEach(function (sheetName) {
      var roa = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets[sheetName]
      );
      if (roa.length > 0) {
        result = roa;
      }
    });
    return result;
  }

  //to_csv
  function to_csv(workbook) {
    // var result = [];
    let result = "";
    workbook.SheetNames.forEach(function (sheetName) {
      var csv = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName]);
      if (csv.length > 0) {
        // result.push('SHEET: ' + sheetName);
        // result.push('\n');
        // result.push(csv);
        result = csv;
      }
    });
    return result.split("\n");
  }

  //讀檔
  var workbook = XLSX.read(inpdata, { type: "binary" });

  //轉為json物件回傳
  if (fmt === "json") {
    return to_json(workbook);
  } else if (fmt === "csv") {
    return to_csv(workbook);
  }
};

const customExcel = (sheetName, rowArray) => {
  const workbook = new ExcelJs.Workbook(); // 創建試算表檔案
  // const sheet = workbook.addWorksheet(sheetName); //在檔案中新增工作表 參數放自訂名稱
  // 使用A4设置的页面设置设置创建新工作表 - 横向
  const sheet = workbook.addWorksheet(sheetName, {
    properties: { defaultColWidth: 13 },
    pageSetup: { paperSize: 9, orientation: "landscape" },
  });

  // sheet.addTable({ // 在工作表裡面指定位置、格式並用columsn與rows屬性填寫內容
  //     name: sheetName,  // 表格內看不到的，讓你之後想要針對這個table去做額外設定的時候，可以指定到這個table
  //     ref: 'A1', // 從A1開始
  //     headerRow: false,
  //     columns: colArray,
  //     rows: rowArray
  // });
  rowArray.forEach((item) => {
    sheet.addRow(item);
  });

  // 表格裡面的資料都填寫完成之後，訂出下載的callback function
  // 異步的等待他處理完之後，創建url與連結，觸發下載
  workbook.xlsx.writeBuffer().then((content) => {
    // const link = document.createElement("a");
    const blobData = new Blob([content], {
      type: "application/vnd.ms-excel;charset=utf-8;",
    });
    $("#excel").attr("download", `${sheetName}.xlsx`);
    $("#excel").attr("href", URL.createObjectURL(blobData));
    // link.download = `${sheetName}.xlsx`;
    // link.href = URL.createObjectURL(blobData);
    // link.click();
  });
};

//Base64 加密
const base64 = (string) => {
  let words = CryptoJS.enc.Utf8.parse(string);
  let base64 = CryptoJS.enc.Base64.stringify(words);
  return encodeURIComponent(base64);
};

//Base64 解密
const debase64 = (string) => {
  let parsedWords = CryptoJS.enc.Base64.parse(string);
  let parsedString = parsedWords.toString(CryptoJS.enc.Utf8);
  return parsedString;
};

//MD5 加密
const md5 = (string) => {
  return CryptoJS.MD5(string).toString();
};

// //AES CBC 128加密
// const encryptByAESModeCBC = (message) => {
//     let key = CryptoJS.enc.Utf8.parse(AesKey);
//     let iv = CryptoJS.enc.Utf8.parse(AesIV);
//     let options = {
//         iv: iv,
//         mode: CryptoJS.mode.CBC,
//         padding: CryptoJS.pad.Pkcs7
//     };

//     let encrypted = CryptoJS.AES.encrypt(message, key, options);
//     return encrypted.toString();
// }

// //AES CBC 128解密
// const decryptByAESModeCBC = (message) => {
//     let key = CryptoJS.enc.Utf8.parse(AesKey);
//     let iv = CryptoJS.enc.Utf8.parse(AesIV);
//     let options = {
//         iv: iv,
//         mode: CryptoJS.mode.CBC,
//         padding: CryptoJS.pad.Pkcs7
//     };

//     let encrypted = CryptoJS.AES.encrypt(message, key, options);
//     return encrypted.toString();
// }

//DES CBC 加密
const encryptByDES = (message) => {
  let key = CryptoJS.enc.Utf8.parse(des.key);
  let iv = CryptoJS.enc.Utf8.parse(des.iv);
  let encrypted = CryptoJS.DES.encrypt(message, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
  // return encrypted.ciphertext.toString();
};

//DES CBC 解密
const decryptDESModeCBC = (message) => {
  let key = CryptoJS.enc.Utf8.parse(des.key);
  let iv = CryptoJS.enc.Utf8.parse(des.iv);
  let decrypted = CryptoJS.DES.decrypt(message, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

//GET,POST Header
const httpHeader = () => {
  return {
    "XY-ChannelId": 29052414,
    "XY-Authorization": encryptByDES(
      `${header.username}:${header.password}:${new Date().getTime() / 1000}`
    ),
  };
};

//場地編號
const isLotStr = (id) => {
  const idStr = {
    P503: "高加-廠一",
    P208: "高加-廠二",
    P504: "高加-第一停車場",
    P505: "高加-第八停車場",
    P506: "H2O",
    P507: "大順特力屋",
    P508: "鳳山特力屋",
    P203: "長榮倉儲(股)公司",
    P205: "竹北遠百",
    P206: "竹北遠百",
    P512: "國立臺灣海洋大學",
    P513: "國立高雄大學",
    P514: "正修科技大學",
    P517: "花蓮文化創意中心",
    P518: "阿里山英迪格",
    P519: "崁頂焚化爐",
  };
  return idStr[id];
};

//排除特殊文字
const strReplace = (str) => {
  const pattern =
    /[`~!@#$^&*()+=\-_|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
  return str.replace(pattern, "");
};

//檢查日期
const isDatetime = (str) => {
  var regex =
    /^(?:19|20)[0-9][0-9]-(?:(?:0[1-9])|(?:1[0-2]))-(?:(?:[0-2][1-9])|(?:[1-3][0-1])) (?:(?:[0-2][0-3])|(?:[0-1][0-9])):[0-5][0-9]:[0-5][0-9]$/;
  if (!regex.test(str)) {
    return false;
  }
  return true;
};

const isTypeDate = (str) => {
  // isNaN(data)排除data为纯数字的情况（此处不考虑只有年份的日期，如‘2021’）
  if (isNaN(str) && !isNaN(Date.parse(str))) {
    return true;
  }
  return false;
};

//檢查電話
const isPhone = (str) => {
  var regex = /^(09)[0-9]{8}$/;
  if (!regex.test(str)) {
    return false;
  }
  return true;
};

//檢查字串是否為正整數
const isNumber = (str) => {
  var regex = /^[0-9]*[1-9][0-9]*$/;
  if (!regex.test(str)) {
    return false;
  }
  return true;
};

//現在時間
const nowTime = () => {
  var date = new Date().format("yyyy-MM-dd HH:mm:ss");
  return date;
};

//格式化時間
const formatTime = (date = null, format = null) => {
  let time = "";
  if (date !== null) {
    if (format !== null) {
      time = new Date(convertDateForIos(date)).getTime();
      time = new Date(time).format(format);
    } else {
      time = new Date(convertDateForIos(date)).getTime();
      time = new Date(time).format("yyyy-MM-dd HH:mm:ss");
    }
  } else {
    if (format !== null) {
      time = new Date().format(format);
    } else {
      time = new Date().format("yyyy-MM-dd HH:mm:ss");
    }
  }
  return time;
};

//iOS 時間顯示修正
const convertDateForIos = (date) => {
  return date.replace(/\-/g, "/");
};

//相差天數
const calTime = (datetime, nowtime) => {
  var date1 = new Date(convertDateForIos(datetime));
  var date2 = new Date(convertDateForIos(nowtime));
  var date3 = date2.getTime() - date1.getTime(); //時間差的毫秒數
  var days = Math.floor(date3 / (24 * 3600 * 1000)); //計算出相差天數
  var leave1 = date3 % (24 * 3600 * 1000); //計算天數後剩餘的毫秒數
  var leave2 = leave1 % (3600 * 1000); //計算小時數後剩餘的毫秒數
  var leave3 = leave2 % (60 * 1000); //計算分鐘數後剩余的毫秒數
  var hours = Math.floor(leave1 / (3600 * 1000)); //計算出小時數
  var minutes = Math.floor(leave2 / (60 * 1000)); //計算相差分鐘數
  var seconds = Math.round(leave3 / 1000); //計算相差秒數
  var result = {
    days: days.toString(),
    hours: hours.toString(),
    minutes: minutes.toString(),
    seconds: seconds.toString(),
  };
  // return JSON.stringify(result);
  return result;
};

//Excel 日期格式數字轉為字串
const excelDate = (number) => {
  let getDate = number - 1;
  let t = Math.round((getDate - Math.floor(getDate)) * 24 * 60 * 60);
  return new Date(1900, 0, getDate, 0, 0, t).format("yyyy-MM-dd HH:mm:ss");
};

const isNullstring = (data) => {
  var toString = data ? data : "無資料";
  return toString;
};

const enter = (element) => {
  if (event.keyCode == 13)
    //enter的鍵值為13
    $(element).click(); //觸動按鈕的點擊
};

import "./daterangepicker.js";
import "../css/daterangepicker.css";
import view from "./Modal.js";
import { logoImg } from "./img.js";
import {
  setUserID,
  setUser,
  setLevel,
  setLot,
  setJson,
  getUserID,
  getUser,
  getLevel,
  getLot,
  getJson,
} from "./var.js";

const isLogin = () => {
  $.ajax({
    type: "GET",
    url: `../api/isLogin.php`,
    dataType: "json",
    headers: httpHeader(),
    success: function (json) {
      let data = json.result.key;
      let status = json.result.logined;
      if (status) {
        // $("section").attr("id", data[0].lot);
        setUserID(data.id);
        setUser(data.username);
        setLevel(data.level);
        // setLot([...new Set(data.lot.split(","))]);
        setLot(data.lot);
        loginHeader(data);
        multiLot(data);
        setJson(data);
        loginFooter();
      } else {
        view.defineShow("訊息", "請登入後繼續...");
        $("#definMsg .modal-footer button").eq(0).addClass("d-none");
        $("#definMsg .modal-footer button")
          .eq(1)
          .off("click")
          .on("click", () => {
            window.location.href = "/";
          });
      }
    },
    error: function (error) {
      console.log(error);
      view.messageShow("錯誤", JSON.stringify(error));
    },
  });
};

const logout = () => {
  $.ajax({
    type: "GET",
    url: `../api/Logout.php`,
    dataType: "json",
    headers: httpHeader(),
    success: function (json) {
      let status = json.result.logined;
      if (!status) {
        window.location.href = `/`;
      }
    },
    error: function (error) {
      console.log(error);
      view.messageShow("錯誤", JSON.stringify(error));
    },
  });
};

const multiLot = (data) => {
  const lot = data.lot.split(",");

  let lotCheck = "";
  lot.forEach((element, index) => {
    if (index === 0) {
      lotCheck += `
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="lotid_${index}" value="${element}" checked>
                <label class="form-check-label" for="lotid_${index}">${isLotStr(
        element
      )}</label>
            </div>
            `;
    } else {
      lotCheck += `
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="lotid_${index}" value="${element}">
                <label class="form-check-label" for="lotid_${index}">${isLotStr(
        element
      )}</label>
            </div>
            `;
    }
  });
  $("#lotList").html(lotCheck);
};

const loginHeader = (data) => {
  //導覽列頁首
  const header = `
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark" aria-label="Navbar">
        <div class="container">
            <div class="d-flex">
                <a href="/central/userInfo.html"><div id="logo"></div></a>
                <span class="text-light ms-2 my-auto">國立高雄大學</span>
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsTop" aria-controls="navbarsTop" aria-expanded="false" aria-label="Header navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-md-end mx-auto px-5" id="navbarsTop">
                <ul class="navbar-nav">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="search" data-bs-toggle="dropdown" aria-expanded="false">查詢</a>
                        <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="search">
                            <li id="header_search_0"><a class="dropdown-item" href="/central/passuserList.html">車證名單</a></li>
                            <li id="header_search_1"><a class="dropdown-item" href="/central/search.html">出入場資料</a></li>
                            <li id="header_search_2"><a class="dropdown-item" href="/central/fee.html">收費明細</a></li>
                            <li id="header_search_3"><a class="dropdown-item" href="/central/feeMulti.html">多機報表</a></li>
                            <li id="header_search_4"><a class="dropdown-item" href="/central/checkOut.html">交接班明細</a></li>
                            <li id="header_search_5"><a class="dropdown-item" href="/central/feeTotal.html">收費統計</a></li>
                            <li id="header_search_6"><a class="dropdown-item" href="/central/invoice.html">發票月報表</a></li>
                            <li id="header_search_7"><a class="dropdown-item" href="/central/instatus.html">發票統計表</a></li>
                            <li id="header_search_8"><a class="dropdown-item" href="/central/writeForm.html">訪客登記資料</a></li>
                            <li id="header_search_9"><a class="dropdown-item" href="/central/export.html">線上支付報表</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="setting" data-bs-toggle="dropdown" aria-expanded="false">設定</a>
                        <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="setting">
                            <li id="header_setting_0"><a class="dropdown-item" href="/central/vipList.html">VIP名單</a></li>
                            <li id="header_setting_1"><a class="dropdown-item" href="/central/officialList.html">公務車名單</a></li>
                            <li id="header_setting_2"><a class="dropdown-item" href="/central/blackList.html">特殊名單</a></li>
                            <li id="header_setting_3"><a class="dropdown-item" href="/central/activityPay.html">單次收費</a></li>
                            <li id="header_setting_4"><a class="dropdown-item" href="/central/payout.html">單位補登及付款</a></li>
                            <li id="header_setting_5"><a class="dropdown-item" href="/central/discode.html">臨時折扣</a></li>
                            <li id="header_setting_6"><a class="dropdown-item" href="/central/feeDay.html">新增平假日費率</a></li>
                            <li id="header_setting_7"><a class="dropdown-item" href="/passusersys/passSetting.html">短期費率及時間</a></li>
                            <li id="header_setting_8"><a class="dropdown-item" href="/central/">設備狀態</a></li>
                            <li id="header_setting_9"><a class="dropdown-item" href="/central/activity.html">單位管理</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="pass" data-bs-toggle="dropdown" aria-expanded="false">申請</a>
                        <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="search">
                            <li id="header_pass_0"><a class="dropdown-item" href="/passusersys/">短期申請</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="user" data-bs-toggle="dropdown" aria-expanded="false">帳號</a>
                        <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="setting">
                            <li id="header_user_0"><a class="dropdown-item" href="/central/userInfo.html">使用者資訊</a></li>
                            <li id="header_user_1"><a class="dropdown-item" href="#">${data.username}登出</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>`;
  $("header").html(header);

  //最高權限外
  if (data.level !== "9") {
    // $("header #search").addClass("d-none");
    $("header #setting").addClass("d-none");
    $("header #pass").addClass("d-none");

    if (data.level === "1") {
      $("header #search").addClass("d-none");
      $("header #setting").removeClass("d-none");
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(item => {
        if (item !== 3) {
          $("#header_setting_" + item).addClass("d-none");
        }
      })
    }
  }

  $("#logo").append(logoImg);

  // const getLogout = document.getElementById("header_user_3").childNodes[0];
  // getLogout.addEventListener("click",logout,false);

  $("#header_user_1 a")
    .off("click")
    .on("click", function () {
      logout();
    });
};

const loginFooter = () => {
  //頁尾
  const footer = `
    <div class="footer bg-light text-center text-lg-start">
        <!-- Copyright -->
        <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
            國立高雄大學 © 2021 Copyright:<a class="text-dark" href="http://www.shinyoun.com.tw/">XinYong Web System</a>
        </div>
        <!-- Copyright -->
    </div>
    `;
  $("footer").html(footer);
};

const dtPicker = (isSingle, element, dt1Element = null, dt2Element = null) => {
  $(element).daterangepicker({
    singleDatePicker: isSingle,
    // "autoApply": true,
    showDropdowns: true,
    timePicker: true,
    timePicker24Hour: true,
    // "timePickerSeconds": true,
    // "startDate": "09/03/2021",
    // "endDate": "09/09/2021",
    opens: "center",
    drops: "auto",
    buttonClasses: "btn",
    ranges: {
      今天: [moment(), moment()],
    },
    locale: {
      format: "YYYY-MM-DD HH:mm",
      customRangeLabel: "自訂義",
      applyLabel: "確認",
      cancelLabel: "取消",
      daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
      monthNames: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],
    },
  });

  $(element).on("apply.daterangepicker", function (ev, picker) {
    $(dt1Element).attr("value", picker.startDate.format("YYYY-MM-DD HH:mm"));
    $(dt2Element).attr("value", picker.startDate.format("YYYY-MM-DD 23:59"));
  });
};

const dtPicker2 = (isSingle, format, element) => {
  $(element).daterangepicker({
    singleDatePicker: isSingle,
    // "autoApply": true,
    showDropdowns: true,
    timePicker: true,
    timePicker24Hour: true,
    // "timePickerSeconds": true,
    startDate: moment().format(format),
    // "endDate": "09/09/2021",
    opens: "center",
    drops: "auto",
    buttonClasses: "btn",
    ranges: {
      今天: [moment(), moment()],
    },
    locale: {
      format: "YYYY-MM-DD HH:mm",
      customRangeLabel: "自訂義",
      applyLabel: "確認",
      cancelLabel: "取消",
      daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
      monthNames: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],
    },
  });
};

const datePicker = (isSingle, format, element, dt1Element) => {
  $(element).daterangepicker({
    singleDatePicker: isSingle,
    autoApply: true,
    showDropdowns: true,
    startDate: moment().format(format),
    // "endDate": "09/09/2021",
    opens: "center",
    drops: "auto",
    buttonClasses: "btn",
    ranges: {
      今天: [moment(), moment()],
    },
    locale: {
      format: "YYYY-MM-DD",
      customRangeLabel: "自訂義",
      applyLabel: "確認",
      cancelLabel: "取消",
      daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
      monthNames: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],
    },
  });

  $(element).on("apply.daterangepicker", function (ev, picker) {
    $(dt1Element).attr("value", picker.startDate.format("YYYY-MM-DD"));
  });
};

export {
  uuid,
  base64,
  debase64,
  md5,
  encryptByDES,
  decryptDESModeCBC,
  // encryptByAESModeCBC,
  // decryptByAESModeCBC,
  httpHeader,
  isLotStr,
  isNumber,
  isPhone,
  nowTime,
  formatTime,
  strReplace,
  isDatetime,
  isTypeDate,
  convertDateForIos,
  calTime,
  isNullstring,
  enter,
  customExcel,
  readxlsx,
  excelDate,
};

export default {
  isLogin: isLogin,
  logout: logout,
  getUserID: getUserID,
  getUser: getUser,
  getLevel: getLevel,
  getLot: getLot,
  getJson: getJson,
  dtPicker: dtPicker,
  dtPicker2: dtPicker2,
  datePicker: datePicker,
};
