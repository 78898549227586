import "../css/bootstrap.min.css";
import "../css/main.css";

import { md5, encryptByDES, httpHeader, nowTime } from "./public.js";
import { userImg, logoImg } from "./img.js";
import { loadDialog } from "./Modal.js";

//自訂介面view
import view from "./Modal.js";

$(function () {
  $("#userImg").html(userImg);
  $("#logoImg").html(logoImg);
  $("#dialogModal").html(loadDialog);

  $.ajax({
    type: "GET",
    url: `api/isLogin.php`,
    dataType: "json",
    headers: httpHeader(),
    success: function (json) {
      let status = json.result.logined;
      if (status) {
        view.loadingShow();
        setTimeout(() => {
          window.location.href = "/central/userInfo.html";
        }, 1000);
      }
    },
    error: function (error) {
      console.log(error);
      view.messageShow("錯誤", JSON.stringify(error));
    },
  });

  $("form").on("submit", function (event) {
    event.preventDefault();

    $.ajax({
      type: "POST",
      url: `api/login.php`,
      data: {
        d: encryptByDES(
          JSON.stringify({
            username: $("#username").val(),
            password: md5($("#password").val()),
            time: nowTime(),
          })
        ),
      },
      dataType: "json",
      headers: httpHeader(),
      beforeSend: function () {
        $("#login").attr({ disabled: "disabled" });
        $("#login").html(`
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="visually-hidden">Loading...</span>
                `);
      },
      complete: function () {
        $("#login").removeAttr("disabled");
        $("#login").text("登入");
      },
      success: function (json) {
        let status = json.result.login;
        if (status) {
          $("#username,#password").removeClass("is-invalid");
          window.location.href = `/central/userInfo.html`;
        } else {
          $("#username,#password").addClass("is-invalid");
        }
      },
      error: function (error) {
        console.log(error);
        view.messageShow("錯誤", JSON.stringify(error));
      },
    });
  });
});
