const userImg = () => {
    var img_url = require("../img/user.png");
    var img = document.createElement("img");
    img.src = img_url.default;
    img.className = "mt-3";
    return img;
}

const loadImg = () => {
    var img_url = require("../img/loading.gif");
    var img = document.createElement("img");
    img.src = img_url.default;
    img.className = "img-fluid mx-auto d-block";
    img.width = 64;
    img.height = 64;
    return img;
}

const logoImg = () => {
    var img_url = require("../img/nukLogo.png");
    var img = document.createElement("img");
    img.src = img_url.default;
    img.alt = "National University of Kaohsiung";
    img.width = 42;
    img.height = 42;
    return img;
}

const formImg = () => {
    var img_url = require("../img/form.png");
    var img = document.createElement("img");
    img.src = img_url.default;
    img.className = "mx-auto d-block";
    return img;
}

const deviceImg = () => {
    var img_url = require("../img/parking_device.png");
    var img = document.createElement("img");
    img.src = img_url.default;
    img.className = "mx-auto d-block";
    return img;
}

const searchImg = () => {
    var img_url = require("../img/find.png");
    var img = document.createElement("img");
    img.src = img_url.default;
    img.className = "mx-auto d-block";
    return img;
}

const feeImg = () => {
    var img_url = require("../img/fee.png");
    var img = document.createElement("img");
    img.src = img_url.default;
    img.className = "mx-auto d-block";
    return img;
}

const discodeImg = () => {
    var img_url = require("../img/discode.png");
    var img = document.createElement("img");
    img.src = img_url.default;
    img.className = "mx-auto d-block";
    return img;
}

const checkImg = () => {
    var img_url = require("../img/check.png");
    var img = document.createElement("img");
    img.src = img_url.default;
    img.className = "mx-auto d-block";
    return img;
}

const onlinePayImg = () => {
    var img_url = require("../img/payment-online.png");
    var img = document.createElement("img");
    img.src = img_url.default;
    img.className = "mx-auto d-block";
    return img;
}

const payOutImg = () => {
    var img_url = require("../img/payout.png");
    var img = document.createElement("img");
    img.src = img_url.default;
    img.className = "mx-auto d-block";
    return img;
}

const excelImg = () => {
    var img_url = require("../img/excel.png");
    var img = document.createElement("img");
    img.src = img_url.default;
    img.className = "mx-auto d-block";
    return img;
}

const passSettingImg = () => {
    var img_url = require("../img/passSetting.png");
    var img = document.createElement("img");
    img.src = img_url.default;
    img.className = "mx-auto d-block";
    return img;
}


export {
    userImg,
    loadImg,
    logoImg,
    formImg,
    deviceImg,
    searchImg,
    feeImg,
    discodeImg,
    passSettingImg,
    checkImg,
    onlinePayImg,
    payOutImg,
    excelImg
}